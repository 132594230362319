<template>
    <el-date-picker
        v-model="date"
        type="datetimerange"
        :picker-options="pickerOptions"
        range-separator="至"
        :start-placeholder="`${name}开始时间`"
        :end-placeholder="`${name}结束时间`"
        align="right"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        class="filter-item"
        @change="changeSelect"
    >
    </el-date-picker>
</template>

<script>
export default {
    props: ['value', 'name'],
    data() {
        return {
            date: '',
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    methods: {
        changeSelect(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value() {
            if (this.value) {
                this.date = this.value;
            }
        }
    }
};
</script>

<style lang="less" scoped></style>
