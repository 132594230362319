/**
 *
 * @param {*} value
 * @param {*} type success info warning danger
 * @returns
 */
function genValue (value, type) {

    let obj = new String(value);
    if (type) {
        obj.type = type;
    }

    return obj;
}

export const collectionTypeMap = {
    "DEFAULT": genValue("默认"),
    "BLIND_BOX": genValue("盲盒"),
    "AUCTION": genValue("拍卖")
};


export const payStatusMap = {
    "UNPAID": genValue("未支付", "info"),
    "HAVE_PAID": genValue("已支付", "success"),
    "FAILED_PAID": genValue("支付失败", "danger"),
    "PENDING_REFUND": genValue("待退款", "danger"),
    "REFUNDING": genValue("退款中", "warning"),
    "REFUNDED": genValue("已退款", "success")
};


export const payMethodMap = {
    "WEIXIN": genValue("微信", "success"),
    "ALIPAY": genValue("支付宝", "")
};


export const assetStatusMap = {
    "NORMAL": genValue("正常", "success"),
    "TRADING": genValue("转让中", "info"),
    "TRANSFERRED": genValue("已转让", "info"),
    "GIFTING": genValue("转赠中", "info"),
    "GIFTED": genValue("已转赠", "danger"),
    "REDEEMED": genValue("已兑换", "danger")
};


export const authStatuMap = {
    "NOT_AUTH": genValue("未认证", "info"),
    "PENDING": genValue("认证中", "info"),
    "SUCCESS": genValue("已认证", "success"),
    "FAIL": genValue("失败", "danger"),
};

export const orderStatusMap = {
    "NOT_PAID": genValue("未支付", "info"),
    "PROCESSING": genValue("已支付，处理中", "info"),
    "FINISH": genValue("已完成", "success"),
    "CANCELLED": genValue("已取消", "danger"),
};


export const divStatusMap = {
    "PENDING": genValue("待处理", "info"),
    "EXPORTED": genValue("已导出", "warning"),
    "PROCESSED": genValue("处理成功", "success"),
    "ERROR": genValue("处理失败", "danger"),
};


export const payPlatformMap = {
    "customPlatform": genValue("官方支付宝", ""),
    "adapayPlatform": genValue("汇付支付平台", "info"),
    "siweipayPlatform": genValue("四维支付平台", "info"),
    "sandpayPlatform": genValue("杉德支付", "warning"),
    "ikudotpayPlatform": genValue("酷点支付", "success"),
    "sumpayPlatform": genValue("商盟支付", "danger"),
    "payeasenetPlatform": genValue("首信易支付", ""),

};
