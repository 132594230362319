<template>
    <el-container id="app">
        <el-aside :width="collapse ? '65px' : '200px'" class="aside">
            <div v-if="collapse" class="logo-wrapper collapse" @click="$router.push('/dashboard')"></div>
            <div v-else class="logo-wrapper" @click="$router.push('/dashboard')">{{logoTitle}}</div>
            <el-menu
                :collapse="collapse"
                :background-color="$theme['menu-bg']"
                :text-color="$theme['menu-text-color']"
                :active-text-color="$theme['menu-text-color-active']"
                :unique-opened="true"
                :router="true"
                :default-active="activeMenu"
                style="border-right: 1px solid #545c64"
                class="el-menu-vertical-demo"
            >
                <sys-menu v-for="item in menus" :menu="item" :key="item.id"> </sys-menu>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header class="app-header">
                <div class="header-btn" @click="collapse = !collapse">
                    <div :style="{ transform: collapse ? 'rotate(90deg)' : '' }">
                        <i class="fas fa-bars" style="font-size: 18px"></i>
                    </div>
                </div>
                <div style="flex-grow: 1; margin-left: 20px">
                    <project-select :value="$store.state.projectId" @input="changeProjectId" @select="changeLogoTitle">
                    </project-select>
                </div>

                <el-tooltip
                    effect="dark"
                    :content="isFullscreen ? '退出全屏' : '全屏'"
                    placement="bottom"
                    :open-delay="1000"
                >
                    <div class="header-btn" @click="toggleFullScreen" ref="fullscreen">
                        <i class="fas fa-expand" style="font-size: 18px"></i>
                    </div>
                </el-tooltip>

                <el-dropdown @command="onCommand" style="margin-left: 20px" trigger="click">
                    <img :src="userInfo ? userInfo.avatar || '' : ''" class="avatar" :title="userInfo && userInfo.username"/>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="pwd" style="word-break: keep-all">修改密码 </el-dropdown-item>
                        <el-dropdown-item command="logout">退出登录 </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-header>
            <el-main class="app-main">
                <router-view></router-view>
            </el-main>
        </el-container>
        <el-dialog
            :visible.sync="showPwdDialog"
            title="修改密码"
            width="500px"
            top="30vh"
            :close-on-click-modal="false"
            custom-class="change-pwd-dialog"
        >
            <el-form :model="pwdForm" label-width="100px" label-position="right" ref="pwdForm" :rules="pwdRules">
                <el-form-item label="输入新密码" prop="password">
                    <el-input v-model="pwdForm.password" type="password" placeholder="输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="repeat">
                    <el-input v-model="pwdForm.repeat" type="password" placeholder="确认新密码"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                    <el-input v-model="pwdForm.code" placeholder="请输入验证码" style="width: 150px"></el-input>
                    <img :src="captcha" class="captcha-image" @click="refreshCaptcha" />
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button @click="showPwdDialog = false">取 消</el-button>
                <el-button type="primary" @click="savePwd" :loading="pwdLoading">确认修改</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>

<script>
import SysMenu from '../components/SysMenu';
import { mapState } from 'vuex';
export default {
    name: 'admin',
    created() {
        this.getMenus();
        let fn = () => {
            this.isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        };
        document.addEventListener('fullscreenchange', fn);
        document.addEventListener('webkitfullscreenchange', fn);
        document.addEventListener('mozfullscreenchange', fn);
        document.addEventListener('MSFullscreenChange', fn);
    },
    data() {
        return {
            logoTitle:"管理后台",
            rawMenus: [],
            menus: [],
            activeMenu: '',
            menuPath: [],
            collapse: localStorage.getItem('menu-collapse') == 'true',
            isFullscreen: false,
            showPwdDialog: false,
            pwdForm: {
                password: '',
                repeat: '',
                code: '',
                key: ''
            },
            captcha: '',
            pwdRules: {
                password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
                repeat: [
                    { required: true, message: '请确认新密码', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (value && value == this.pwdForm.password) {
                                callback();
                            } else {
                                callback(new Error('两次密码输入不一致'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            this.$http
                                .get('/captcha/verify', {
                                    key: this.pwdForm.key,
                                    code: value
                                })
                                .then(res => {
                                    if (res === true) {
                                        callback();
                                    } else {
                                        callback(new Error('验证码错误'));
                                    }
                                })
                                .catch(e => {
                                    callback(new Error('验证码错误'));
                                });
                        },
                        trigger: 'blur'
                    }
                ]
            },
            pwdLoading: false
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        findActiveMenu() {
            this.activeMenu = '';
            this.menuPath = [];
            let path = this.$route.path;
            const findActiveMenu = (parents, childMenus) => {
                childMenus.forEach(i => {
                    let parents_copy = [...parents];
                    if (i.path === path) {
                        parents_copy.push(i);
                        this.menuPath = parents_copy.map(i => i.name);
                        this.activeMenu = '' + i.id;
                    } else {
                        if (i.children) {
                            parents_copy.push(i);
                            findActiveMenu(parents_copy, i.children);
                        }
                    }
                });
            };
            findActiveMenu([], this.rawMenus);
        },
        getMenus() {
            this.$http.get('/menu/userMenu').then(res => {
                this.rawMenus = res;
                this.menus = res;
                this.findActiveMenu();
            });
        },
        toggleFullScreen() {
            this.isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
            let element = document.body;
            let requestMethod;
            if (this.isFullscreen) {
                requestMethod =
                    document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen;
            } else {
                requestMethod =
                    element.requestFullScreen ||
                    element.webkitRequestFullScreen ||
                    element.mozRequestFullScreen ||
                    element.msRequestFullScreen;
            }
            if (requestMethod) {
                requestMethod.call(this.isFullscreen ? document : element);
            }
        },
        onCommand(command) {
            if (command === 'logout') {
                localStorage.removeItem('token');
                this.$store.commit('updateUserInfo', null);
                this.$router.replace('/login');
            } else if (command === 'pwd') {
                this.onShowPwdDialog();
            }
        },
        onShowPwdDialog() {
            this.$refs.pwdForm && this.$refs.pwdForm.resetFields();
            // this.$http.get('/captcha/get').then(res => {
            //     this.captcha = res.image;
            //     this.pwdForm = {
            //         password: '',
            //         repeat: '',
            //         code: '',
            //         key: res.key
            //     };
            //     this.showPwdDialog = true;
            // });

            this.showPwdDialog = true;
        },
        savePwd() {
            this.pwdLoading = true;
            this.$refs.pwdForm.validate(valid => {
                if (valid) {
                    this.$http
                        .post('/user/setPasswordAdmin', {
                            userId: this.userInfo.id,
                            password: this.pwdForm.password
                        })
                        .then(res => {
                            console.log(res);
                            this.pwdLoading = false;
                            this.showPwdDialog = false;
                            localStorage.removeItem('token');
                            this.$store.commit('updateUserInfo', null);
                            this.$router.replace('/login');
                            this.$message.success('修改成功，请重新登录');
                        })
                        .catch(e => {
                            this.pwdLoading = false;
                            this.$message.error(e.error || '修改失败');
                        });
                } else {
                    this.pwdLoading = false;
                }
            });
        },
        refreshCaptcha() {
            this.$http.get('/captcha/get').then(res => {
                this.captcha = res.image;
                this.pwdForm.key = res.key;
            });
        },
        changeProjectId(projectId) {
            this.$store.commit('setProjectId', projectId);
        },
        changeLogoTitle(project){
            if(project && project.chName){
                this.logoTitle = project.chName;
            }
        }
    },
    watch: {
        $route(val) {
            this.findActiveMenu(this.rawMenus);
        },
        isFullscreen(val) {
            this.$refs.fullscreen.innerHTML = '';
            let i = document.createElement('i');
            i.style.fontSize = '20px';
            i.className = val ? 'fas fa-compress' : 'fas fa-expand';
            this.$refs.fullscreen.append(i);
            FontAwesome.dom.i2svg();
        },
        collapse(val) {
            localStorage.setItem('menu-collapse', val);
        }
    },
    components: {
        SysMenu
    }
};
</script>
<style lang="less" scoped>
#app {
    height: 100%;
}
/deep/ .aside {
    .el-menu {
        border: none !important;
        &:not(.el-menu--collapse) {
            width: 200px;
        }
    }
}
/deep/ .change-pwd-dialog {
    .captcha-image {
        height: 32px;
        vertical-align: middle;
        margin-left: 10px;
        cursor: pointer;
    }
    .el-dialog__body {
        padding-bottom: 0;
    }
}
.app-header {
    font-size: 0;
    height: 52px !important;
    padding: 0 !important;
    color: #303133;
    background: #fff;
    display: flex;
    align-items: center;
    padding-left: 0;
    box-sizing: border-box;
    position: relative;
    &::after {
        .setBottomLine();
    }
    .header-btn {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;
        & > div {
            transition: all 0.3s;
        }
        &:hover {
            background: fade(black, 10%);
        }
    }
    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 20px;
        cursor: pointer;
    }
    a {
        &:visited {
            color: @text1;
        }
    }
}

.aside {
    background: @menu-bg;
    transition: all 0.4s ease;
    -ms-overflow-style: none;
    .logo-wrapper {
        font-weight: 200;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        color: white;
        font-size: 20px;
        background: fade(black, 20%);
        cursor: pointer;
        &.collapse {
            display: block;
            font-size: 14px;
            text-align: center;
            line-height: 16px;
            box-sizing: border-box;
            padding: 10px 0;
        }
    }
}

.aside::-webkit-scrollbar {
    display: none;
}

.app-main {
    background: @bg;
    height: 100%;
    padding: 0 !important;
}
</style>
