<template>
    <el-select
        v-model="minterId"
        filterable
        remote
        reserve-keyword
        placeholder="请输入关键词"
        :remote-method="searchMinter"
        :loading="searchingMinter"
        @change="onChange"
        :disabled="disabled"
    >
        <el-option
            v-for="item in mergedOptions"
            :key="item.id"
            :label="item.nickname"
            :value="item.id"
            class="minter-item"
        >
            <el-image :src="item.avatar" fit="cover" class="avatar"></el-image>
            <div class="content">
                <div class="name">{{ item.nickname }}</div>
                <div class="id">#{{ item.id }}</div>
            </div>
        </el-option>
    </el-select>
</template>
<script>
export default {
    props: {
        value: {},
        disabled: {
            default() {
                return false;
            }
        },
        projectId: {
            default: 0
        }
    },
    data() {
        return {
            minters: [],
            minterId: null,
            searchingMinter: false,
            selected: null,
            id: 0
        };
    },
    created() {
        this.id = this.projectId;
        this.getData();
        if (this.value) {
            this.$http.get(`/user/get/${this.value}`).then(res => {
                this.selected = res;
            });
        }
    },
    computed: {
        mergedOptions() {
            if (this.selected && !this.minters.find(i => i.id === this.selected.id)) {
                return [{ ...this.selected }, ...this.minters];
            } else {
                return this.minters;
            }
        }
    },
    methods: {
        searchMinter(query) {
            this.searchingMinter = true;
            this.$http
                .post(
                    '/user/all',
                    {
                        search: query,
                        size: 30,
                        query: { hasRole: 'ROLE_MINTER', minterProjectId: this.projectId }
                    },
                    { body: 'json' }
                )
                .then(res => {
                    this.minters = res.content;
                    this.searchingMinter = false;
                });
        },
        onChange(e) {
            if (e) {
                this.$http.get(`/user/get/${e}`).then(res => {
                    this.selected = res;
                });
            }
        },
        getData() {
            this.$http
                .post(
                    '/user/all',
                    { size: 30, query: { hasRole: 'ROLE_MINTER', minterProjectId: this.id } },
                    { body: 'json' }
                )
                .then(res => {
                    this.minters = res.content;
                });
        }
    },
    watch: {
        minterId(val) {
            this.$emit('input', val);
        },
        value(val) {
            if (this.minterId !== val) {
                this.minterId = val;
            }
            if (!(val && this.selected && this.selected.id === val)) {
                this.$http.get(`/user/get/${val}`).then(res => {
                    this.selected = res;
                });
            }
        },
        selected(val) {
            this.$emit('detail', val);
        },
        projectId(val) {
            if (this.id !== val) {
                this.id = val;
                this.getData();
            }
        }
    }
};
</script>
<style lang="less" scoped>
.minter-item {
    .flex();
    height: 44px;
    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    .content {
        margin-left: 10px;
        line-height: 1;
        .flex-col();
        justify-content: center;
        .name {
            font-size: 14px;
            color: @text2;
        }
        .id {
            font-size: 12px;
            color: @text3;
            margin-top: 5px;
        }
    }
}
</style>