<template>
    <div style="display: inline-block">
        <el-cascader
            :props="props"
            filterable
            v-model="chooseValue"
            v-if="show"
            @change="onChange"
            ref="cascader"
        ></el-cascader>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Array
        },
        checkStrictly: {
            type: Boolean,
            default: false
        },
        maxLevel: {}
    },
    data() {
        return {
            show: true,
            chooseValue: [],
            props: {
                lazy: true,
                lazyLoad: (node, resolve) => {
                    const { level } = node;
                    let params = { size: 10000, query: { level: level } };
                    if (level === 0) {
                        params.level = 'PROVINCE';
                    } else {
                        params.parent = node.data.id;
                    }
                    if (this.maxLevel) {
                        params.maxLevel = this.maxLevel.toUpperCase();
                    }
                    this.$http.get('/district', params).then(res => {
                        if (res.length === 0) {
                            this.$set(node, 'isLeaf', true);
                            node.hasChildren = false;
                            resolve(null);
                            return;
                        }
                        resolve(res);
                    });
                },
                value: 'id',
                label: 'name',
                leaf: 'leaf',
                checkStrictly: this.checkStrictly
            },
            emiting: false
        };
    },
    created() {
        if (this.value) {
            this.chooseValue = this.value;
        }
    },
    methods: {
        onChange(e) {
            this.emiting = true;
            this.$emit('input', [...e]);
            this.$emit('select', this.$refs.cascader.getCheckedNodes());
            this.$nextTick(() => {
                this.emiting = false;
            });
        }
    },
    watch: {
        value(val) {
            if (this.emiting) return;
            this.show = false;
            this.$nextTick(() => {
                this.chooseValue = val;
                this.show = true;
            });
        }
    }
};
</script>
