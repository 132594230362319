<template>
    <el-menu-item v-if="isLeaf" :index="'' + menu.id" :route="{ path: menu.path }">
        <i class="fa-fw" :class="menu.icon" v-if="menu.icon"></i><span slot="title">{{ menu.name }}</span>
    </el-menu-item>
    <el-submenu v-else :index="'' + menu.id">
        <template slot="title">
            <i class="fa-fw" :class="menu.icon" v-if="menu.icon"></i>
            <span slot="title">{{ menu.name }}</span>
        </template>
        <sys-menu v-for="item in menu.children" :menu="item" :key="item.id"></sys-menu>
    </el-submenu>
</template>
<script>
export default {
    name: 'SysMenu',
    props: {
        menu: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    },
    methods: {
        click(e) {
            console.log(e);
        }
    },
    computed: {
        isLeaf() {
            return !(this.menu.children instanceof Array && this.menu.children.length);
        }
    }
};
</script>
