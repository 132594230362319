import Vue from 'vue';
import Router from 'vue-router';
import Admin from '@/views/Admin';
import store from './store';
import http from './plugins/http';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            component: Admin,
            children: [{
                    path: '/',
                    redirect: 'dashboard'
                },
                {
                    path: '/404',
                    name: '404',
                    component: () => import( /* webpackChunkName: "404" */ '@/views/404.vue')
                },
                // {
                //     path: '/dashboard',
                //     name: 'dashboard',
                //     component: () => import(/* webpackChunkName: "404" */ '@/views/Dashboard.vue'),
                //     meta: {
                //         title: '首页'
                //     }
                // },
                {
                    path: '/dashboard',
                    redirect: 'collectionList'
                },
                {
                    path: '/api',
                    name: 'api',
                    component: () => import( /* webpackChunkName: "api" */ '@/views/Api.vue'),
                    meta: {
                        title: '接口调试'
                    }
                },
                {
                    path: '/genCodeEdit',
                    name: 'genCodeEdit',
                    component: () => import( /* webpackChunkName: "genCodeEdit" */ '@/views/GenCodeEdit.vue'),
                    meta: {
                        title: '代码生成编辑'
                    }
                },
                {
                    path: '/genCodeList',
                    name: 'genCodeList',
                    component: () => import( /* webpackChunkName: "genCodeList" */ '@/views/GenCodeList.vue'),
                    meta: {
                        title: '代码生成'
                    }
                },
                {
                    path: '/menus',
                    name: 'menus',
                    component: () => import( /* webpackChunkName: "menus" */ '@/views/Menus.vue'),
                    meta: {
                        title: '菜单配置'
                    }
                },
                {
                    path: '/menuAuthority',
                    name: 'menuAuthority',
                    component: () => import( /* webpackChunkName: "menuAuthority" */ '@/views/MenuAuthority.vue'),
                    meta: {
                        title: '菜单权限'
                    }
                },
                {
                    path: '/userEdit',
                    name: 'userEdit',
                    component: () => import( /* webpackChunkName: "userEdit" */ '@/views/UserEdit.vue'),
                    meta: {
                        title: '用户编辑'
                    }
                },
                {
                    path: '/userList',
                    name: 'userList',
                    component: () => import( /* webpackChunkName: "userList" */ '@/views/UserList.vue'),
                    meta: {
                        title: '用户管理'
                    }
                },
                {
                    path: '/sysConfigList',
                    name: 'sysConfigList',
                    component: () => import( /* webpackChunkName: "sysConfigList" */ '@/views/SysConfigList.vue'),
                    meta: {
                        title: '参数配置'
                    }
                },
                {
                    path: '/upload',
                    name: 'upload',
                    component: () => import( /* webpackChunkName: "upload" */ '@/views/Upload.vue'),
                    meta: {
                        title: '上传'
                    }
                },
                {
                    path: '/testClassEdit',
                    name: 'TestClassEdit',
                    component: () => import( /* webpackChunkName: "testClassEdit" */ '@/views/TestClassEdit.vue'),
                    meta: {
                        title: '测试编辑'
                    }
                },
                {
                    path: '/testClassList',
                    name: 'TestClassList',
                    component: () => import( /* webpackChunkName: "testClassList" */ '@/views/TestClassList.vue'),
                    meta: {
                        title: '测试'
                    }
                },
                {
                    path: '/operationLogList',
                    name: 'OperationLogList',
                    component: () => import( /* webpackChunkName: "operationLogList" */ '@/views/OperationLogList.vue'),
                    meta: {
                        title: '操作日志'
                    }
                },
                {
                    path: '/exceptionLogList',
                    name: 'ExceptionLogList',
                    component: () => import( /* webpackChunkName: "exceptionLogList" */ '@/views/ExceptionLogList.vue'),
                    meta: {
                        title: '异常日志'
                    }
                },
                {
                    path: '/collectionEdit',
                    name: 'CollectionEdit',
                    component: () => import( /* webpackChunkName: "collectionEdit" */ '@/views/CollectionEdit.vue'),
                    meta: {
                        title: '藏品管理编辑'
                    }
                },
                {
                    path: '/collectionList',
                    name: 'CollectionList',
                    component: () => import( /* webpackChunkName: "collectionList" */ '@/views/CollectionList.vue'),
                    meta: {
                        title: '藏品管理'
                    }
                },
                {
                    path: '/collectionClassList',
                    name: 'CollectionClassList',
                    component: () => import( /* webpackChunkName: "collectionList" */ '@/views/CollectionClassList.vue'),
                    meta: {
                        title: '藏品分类'
                    }
                },
                {
                    path: '/collectionClassEdit',
                    name: 'CollectionClassEdit',
                    component: () => import( /* webpackChunkName: "collectionList" */ '@/views/CollectionClassEdit.vue'),
                    meta: {
                        title: '分类编辑'
                    }
                },
                {
                    path: '/noticeCategoryList',
                    name: 'NoticeCategoryList',
                    component: () => import( /* webpackChunkName: "collectionList" */ '@/views/NoticeCategoryList.vue'),
                    meta: {
                        title: '广告分类'
                    }
                },
                {
                    path: '/noticeCategoryEdit',
                    name: 'NoticeCategoryEdit',
                    component: () => import( /* webpackChunkName: "collectionList" */ '@/views/NoticeCategoryEdit.vue'),
                    meta: {
                        title: '分类编辑'
                    }
                },
                {
                    path: '/orderEdit',
                    name: 'OrderEdit',
                    component: () => import( /* webpackChunkName: "orderEdit" */ '@/views/OrderEdit.vue'),
                    meta: {
                        title: '订单编辑'
                    }
                },
                {
                    path: '/orderList',
                    name: 'OrderList',
                    component: () => import( /* webpackChunkName: "orderList" */ '@/views/OrderList.vue'),
                    meta: {
                        title: '官方购买'
                    }
                },
                {
                    path: '/orderUsedList',
                    name: 'OrderUsedList',
                    component: () => import( /* webpackChunkName: "orderUsedList" */ '@/views/OrderUsedList.vue'),
                    meta: {
                        title: '二手市场'
                    }
                },
                {
                    path: '/assetEdit',
                    name: 'AssetEdit',
                    component: () => import( /* webpackChunkName: "assetEdit" */ '@/views/AssetEdit.vue'),
                    meta: {
                        title: '资产编辑'
                    }
                },
                {
                    path: '/assetList',
                    name: 'AssetList',
                    component: () => import( /* webpackChunkName: "assetList" */ '@/views/AssetList.vue'),
                    meta: {
                        title: '资产'
                    }
                },
                {
                    path: '/likeEdit',
                    name: 'LikeEdit',
                    component: () => import( /* webpackChunkName: "likeEdit" */ '@/views/LikeEdit.vue'),
                    meta: {
                        title: '点赞编辑'
                    }
                },
                {
                    path: '/likeList',
                    name: 'LikeList',
                    component: () => import( /* webpackChunkName: "likeList" */ '@/views/LikeList.vue'),
                    meta: {
                        title: '点赞'
                    }
                },
                {
                    path: '/followEdit',
                    name: 'FollowEdit',
                    component: () => import( /* webpackChunkName: "followEdit" */ '@/views/FollowEdit.vue'),
                    meta: {
                        title: '关注编辑'
                    }
                },
                {
                    path: '/followList',
                    name: 'FollowList',
                    component: () => import( /* webpackChunkName: "followList" */ '@/views/FollowList.vue'),
                    meta: {
                        title: '关注'
                    }
                },
                {
                    path: '/minterEdit',
                    name: 'MinterEdit',
                    component: () => import( /* webpackChunkName: "minterEdit" */ '@/views/MinterEdit.vue'),
                    meta: {
                        title: '铸造者编辑'
                    }
                },
                {
                    path: '/minterList',
                    name: 'MinterList',
                    component: () => import( /* webpackChunkName: "minterList" */ '@/views/MinterList.vue'),
                    meta: {
                        title: '铸造者列表'
                    }
                },
                {
                    path: '/identityAuthEdit',
                    name: 'IdentityAuthEdit',
                    component: () => import( /* webpackChunkName: "identityAuthEdit" */ '@/views/IdentityAuthEdit.vue'),
                    meta: {
                        title: '身份认证编辑'
                    }
                },
                {
                    path: '/identityAuthList',
                    name: 'IdentityAuthList',
                    component: () => import( /* webpackChunkName: "identityAuthList" */ '@/views/IdentityAuthList.vue'),
                    meta: {
                        title: '身份认证'
                    }
                },
                {
                    path: '/bannerEdit',
                    name: 'BannerEdit',
                    component: () => import( /* webpackChunkName: "bannerEdit" */ '@/views/BannerEdit.vue'),
                    meta: {
                        title: '轮播图编辑'
                    }
                },
                {
                    path: '/bannerList',
                    name: 'BannerList',
                    component: () => import( /* webpackChunkName: "bannerList" */ '@/views/BannerList.vue'),
                    meta: {
                        title: '轮播图'
                    }
                },
                {
                    path: '/noticeBroadcastEdit',
                    name: 'NoticeBroadcastEdit',
                    component: () => import( /* webpackChunkName: "noticeBroadcastEdit" */ '@/views/NoticeBroadcastEdit.vue'),
                    meta: {
                        title: '公告广播编辑'
                    }
                },
                {
                    path: '/noticeBroadcastList',
                    name: 'NoticeBroadcastList',
                    component: () => import( /* webpackChunkName: "noticeBroadcastList" */ '@/views/NoticeBroadcastList.vue'),
                    meta: {
                        title: '公告广播'
                    }
                },
                {
                    path: '/storyBackgroundEdit',
                    name: 'StoryBackgroundEdit',
                    component: () => import( /* webpackChunkName: "storyBackgroundEdit" */ '@/views/StoryBackgroundEdit.vue'),
                    meta: {
                        title: '故事背景编辑'
                    }
                },
                {
                    path: '/storyBackgroundList',
                    name: 'StoryBackgroundList',
                    component: () => import( /* webpackChunkName: "storyBackgroundList" */ '@/views/StoryBackgroundList.vue'),
                    meta: {
                        title: '故事背景'
                    }
                },
                {
                    path: '/blindBoxEdit',
                    name: 'BlindBoxEdit',
                    component: () => import( /* webpackChunkName: "blindBoxEdit" */ '@/views/BlindBoxEdit.vue'),
                    meta: {
                        title: '盲盒编辑'
                    }
                },
                {
                    path: '/blindBoxList',
                    name: 'BlindBoxList',
                    component: () => import( /* webpackChunkName: "blindBoxList" */ '@/views/BlindBoxList.vue'),
                    meta: {
                        title: '盲盒'
                    }
                },
                {
                    path: '/adminEdit',
                    name: 'adminEdit',
                    component: () => import( /* webpackChunkName: "adminEdit" */ '@/views/AdminEdit.vue'),
                    meta: {
                        title: '账号编辑'
                    }
                },
                {
                    path: '/adminList',
                    name: 'AdminList',
                    component: () => import( /* webpackChunkName: "adminList" */ '@/views/AdminList.vue'),
                    meta: {
                        title: '账号管理'
                    }
                },
                {
                    path: '/privilegeOptionEdit',
                    name: 'PrivilegeOptionEdit',
                    component: () =>
                        import( /* webpackChunkName: "privilegeOptionEdit" */ '@/views/PrivilegeOptionEdit.vue'),
                    meta: {
                        title: '特权配置编辑'
                    }
                },
                {
                    path: '/privilegeOptionList',
                    name: 'PrivilegeOptionList',
                    component: () =>
                        import( /* webpackChunkName: "privilegeOptionList" */ '@/views/PrivilegeOptionList.vue'),
                    meta: {
                        title: '特权配置'
                    }
                },
                {
                    path: '/airDropEdit',
                    name: 'AirDropEdit',
                    component: () => import( /* webpackChunkName: "airDropEdit" */ '@/views/AirDropEdit.vue'),
                    meta: {
                        title: '空投编辑'
                    }
                },
                {
                    path: '/addAllRow',
                    name: 'addAllRow',
                    component: () => import( /* webpackChunkName: "airDropEdit" */ '@/views/addAllRow.vue'),
                    meta: {
                        title: '空投导入'
                    }
                },
                {
                    path: '/airDropTime',
                    name: 'AirDropTime',
                    component: () => import( /* webpackChunkName: "airDropAdd" */ '@/views/AirDropTime.vue'),
                    meta: {
                        title: '批量空投'
                    }
                },
                {
                    path: '/airDropAdd',
                    name: 'AirDropAdd',
                    component: () => import( /* webpackChunkName: "airDropAdd" */ '@/views/AirDropAdd.vue'),
                    meta: {
                        title: '批量空投'
                    }
                },
                {
                    path: '/airDropList',
                    name: 'AirDropList',
                    component: () => import( /* webpackChunkName: "airDropList" */ '@/views/AirDropList.vue'),
                    meta: {
                        title: '空投'
                    }
                },
                {
                            path: '/whiteList',
                            name: 'whiteList',
                            component: () => import( /* webpackChunkName: "whiteList" */ '@/views/whiteList.vue'),
                            meta: {
                                title: '批量白名单'
                            }
                 },
                {
                    path: '/couponEdit',
                    name: 'CouponEdit',
                    component: () => import( /* webpackChunkName: "couponEdit" */ '@/views/CouponEdit.vue'),
                    meta: {
                        title: '兑换券编辑'
                    }
                },
                {
                    path: '/couponList',
                    name: 'CouponList',
                    component: () => import( /* webpackChunkName: "couponList" */ '@/views/CouponList.vue'),
                    meta: {
                        title: '兑换券'
                    }
                },
                {
                    path: '/commissionRecordEdit',
                    name: 'CommissionRecordEdit',
                    component: () =>
                        import( /* webpackChunkName: "commissionRecordEdit" */ '@/views/CommissionRecordEdit.vue'),
                    meta: {
                        title: '分销记录编辑'
                    }
                },
                {
                    path: '/commissionRecordList',
                    name: 'CommissionRecordList',
                    component: () =>
                        import( /* webpackChunkName: "commissionRecordList" */ '@/views/CommissionRecordList.vue'),
                    meta: {
                        title: '分销记录'
                    }
                },
                {
                    path: '/recommendEdit',
                    name: 'RecommendEdit',
                    component: () => import( /* webpackChunkName: "recommendEdit" */ '@/views/RecommendEdit.vue'),
                    meta: {
                        title: '首页推荐编辑'
                    }
                },
                {
                    path: '/recommendList',
                    name: 'RecommendList',
                    component: () => import( /* webpackChunkName: "recommendList" */ '@/views/RecommendList.vue'),
                    meta: {
                        title: '首页推荐'
                    }
                },
                {
                    path: '/activityEdit',
                    name: 'ActivityEdit',
                    component: () => import( /* webpackChunkName: "activityEdit" */ '@/views/ActivityEdit.vue'),
                    meta: {
                        title: '活动编辑'
                    }
                },
                {
                    path: '/activityList',
                    name: 'ActivityList',
                    component: () => import( /* webpackChunkName: "activityList" */ '@/views/ActivityList.vue'),
                    meta: {
                        title: '活动'
                    }
                },

                {
                    path: '/preemptionEdit',
                    name: 'PreemptionEdit',
                    component: () => import( /* webpackChunkName: "PreemptionEdit" */ '@/views/PreemptionEdit.vue'),
                    meta: {
                        title: '优先购活动编辑'
                    }
                },
                {
                    path: '/preemptionList',
                    name: 'PreemptionList',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/PreemptionList.vue'),
                    meta: {
                        title: '优先购活动'
                    }
                },
                {
                    path: '/activityDrawLotsList',
                    name: 'ActivityDrawLotsList',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/DrawLotsList.vue'),
                    meta: {
                        title: '申购活动'
                    }
                },
                {
                    path: '/purchaseUserList',
                    name: 'PurchaseUserList',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/PurchaseUserList.vue'),
                    meta: {
                        title: '申购名单'
                    }
                },
                {
                    path: '/activityDrawLotsEdit',
                    name: 'ActivityDrawLotsEdit',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/DrawLotsEdit.vue'),
                    meta: {
                        title: '申购活动编辑'
                    }
                },
                {
                    path: '/activityDrawLotsLogList',
                    name: 'ActivityDrawLotsLogList',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/DrawLotsLogList.vue'),
                    meta: {
                        title: '申购记录'
                    }
                },

                {
                    path: '/activityDrawLotsLogEdit',
                    name: 'ActivityDrawLotsLogEdit',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/DrawLotsLogEdit.vue'),
                    meta: {
                        title: '申购记录编辑'
                    }
                },
                {
                    path: '/surpriseBoxEdit',
                    name: 'SurpriseBoxEdit',
                    component: () => import( /* webpackChunkName: "PreemptionEdit" */ '@/views/SurpriseBoxEdit.vue'),
                    meta: {
                        title: '惊喜盒子活动编辑'
                    }
                },
                {
                    path: '/surpriseBoxList',
                    name: 'SurpriseBoxList',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/SurpriseBoxList.vue'),
                    meta: {
                        title: '惊喜盒子活动'
                    }
                },
                {
                    path: '/supBoxList',
                    name: 'SupBoxList',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/SupBoxList.vue'),
                    meta: {
                        title: '惊喜盒子中奖名单'
                    }
                },

                {
                    path: '/cardInfoList',
                    name: 'CardInfoList',
                    component: () => import( /* webpackChunkName: "PreemptionList" */ '@/views/CardInfoList.vue'),
                    meta: {
                        title: '银行卡列表'
                    }
                },
                {
                    path: '/preemptionWhiteEdit',
                    name: 'PreemptionWhiteEdit',
                    component: () =>
                        import( /* webpackChunkName: "PreemptionWhiteEdit" */ '@/views/PreemptionWhiteEdit.vue'),
                    meta: {
                        title: '白名单编辑'
                    }
                },
                {
                    path: '/preemptionWhiteList',
                    name: 'PreemptionWhiteList',
                    component: () =>
                        import( /* webpackChunkName: "PreemptionWhiteList" */ '@/views/PreemptionWhiteList.vue'),
                    meta: {
                        title: '白名单'
                    }
                },

                {
                    path: '/inviteEdit',
                    name: 'InviteEdit',
                    component: () => import( /* webpackChunkName: "inviteEdit" */ '@/views/InviteEdit.vue'),
                    meta: {
                        title: '邀请码管理编辑'
                    }
                },
                {
                    path: '/inviteList',
                    name: 'InviteList',
                    component: () => import( /* webpackChunkName: "inviteList" */ '@/views/InviteList.vue'),
                    meta: {
                        title: '邀请码管理'
                    }
                },
                {
                    path: '/invitationList',
                    name: 'InvitationList',
                    component: () => import( /* webpackChunkName: "inviteList" */ '@/views/InvitationList.vue'),
                    meta: {
                        title: '邀请有礼'
                    }
                },
                {
                    path: '/invitationEdit',
                    name: 'InvitationEdit',
                    component: () => import( /* webpackChunkName: "inviteEdit" */ '@/views/InvitationEdit.vue'),
                    meta: {
                        title: '邀请有礼编辑'
                    }
                },
                {
                    path: '/giftOrderEdit',
                    name: 'GiftOrderEdit',
                    component: () => import( /* webpackChunkName: "giftOrderEdit" */ '@/views/GiftOrderEdit.vue'),
                    meta: {
                        title: '转赠订单编辑'
                    }
                },
                {
                    path: '/giftOrderList',
                    name: 'GiftOrderList',
                    component: () => import( /* webpackChunkName: "giftOrderList" */ '@/views/GiftOrderList.vue'),
                    meta: {
                        title: '转赠订单'
                    }
                },
                {
                    path: '/adapayMerchantEdit',
                    name: 'AdapayMerchantEdit',
                    component: () =>
                        import( /* webpackChunkName: "adapayMerchantEdit" */ '@/views/AdapayMerchantEdit.vue'),
                    meta: {
                        title: 'ada编辑'
                    }
                },
                {
                    path: '/adapayMerchantList',
                    name: 'AdapayMerchantList',
                    component: () =>
                        import( /* webpackChunkName: "adapayMerchantList" */ '@/views/AdapayMerchantList.vue'),
                    meta: {
                        title: 'ada'
                    }
                },
                {
                    path: '/activityCollectionEdit',
                    name: 'ActivityCollectionEdit',
                    component: () =>
                        import( /* webpackChunkName: "activityCollectionEdit" */ '@/views/ActivityCollectionEdit.vue'),
                    meta: {
                        title: '活动收集编辑'
                    }
                },
                {
                    path: '/activityCollectionList',
                    name: 'ActivityCollectionList',
                    component: () =>
                        import( /* webpackChunkName: "activityCollectionList" */ '@/views/ActivityCollectionList.vue'),
                    meta: {
                        title: '活动收集'
                    }
                },
                {
                    path: '/activityOrderEdit',
                    name: 'ActivityOrderEdit',
                    component: () =>
                        import( /* webpackChunkName: "activityOrderEdit" */ '@/views/ActivityOrderEdit.vue'),
                    meta: {
                        title: '收集记录编辑'
                    }
                },
                {
                    path: '/activityOrderList',
                    name: 'ActivityOrderList',
                    component: () =>
                        import( /* webpackChunkName: "activityOrderList" */ '@/views/ActivityOrderList.vue'),
                    meta: {
                        title: '收集记录'
                    }
                },
                {
                    path: '/paymentInfoList',
                    name: 'paymentInfoList',
                    component: () => import( /* webpackChunkName: "paymentInfoList" */ '@/views/PaymentInfoList.vue'),
                    meta: {
                        title: '支付信息记录'
                    }
                },
                {
                    path: '/divMoneyList',
                    name: 'divMoneyList',
                    component: () => import('@/views/DivMoneyList.vue'),
                    meta: {
                        title: '分账记录'
                    }
                },
                {
                    path: '/projectList',
                    name: 'projectList',
                    component: () => import('@/views/ProjectList.vue'),
                    meta: {
                        title: '项目管理'
                    }
                }
                // ,
                // {
                //     path: '/assetSellLimitList',
                //     name: 'assetSellLimitList',
                //     component: () => import('@/views/AssetSellLimitList.vue'),
                //     meta: {
                //         title: '资产售卖限制'
                //     }
                // }
                /**INSERT_LOCATION**/
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login'),
            meta: {
                title: '登录'
            }
        },
        {
            path: '/photoProcessing',
            name: 'photoProcessing',
            component: () => import('@/views/PhotoProcessing'),
            meta: {
                title: '图片处理'
            }
        },
        {
            path: '*',
            name: 'notFound',
            component: () => import('@/views/404.vue')
        }
    ]
});
router.beforeEach((to, from, next) => {
    //console.log(to);
    if (/^\/http/.test(to.path)) {
        console.log(Vue.$baseUrl);
        let url = to.path.replace('/', '');
        let params = [];
        if (to.query) {
            for (let key in to.query) {
                if (to.query.hasOwnProperty(key)) {
                    params.push(`${key}=${to.query[key]}`);
                }
            }
        }
        if (params.length > 0) {
            url += `?${params.join('&')}`;
        }
        window.open(url);
        return;
    }
    if (!store.state.userInfo && to.path !== '/login' && to.path !== '/photoProcessing') {
        http.axios
            .get('/user/my')
            .then(res => {
                store.commit('updateUserInfo', res.data);
                next();
                return;
            })
            .catch(() => {
                localStorage.removeItem('token');
                next('/login');
            });
    } else if (!to.matched.length) {
        next('/404');
    } else {
        next();
    }
});

export default router;
