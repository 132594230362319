<template>
  <el-select v-model="collectionId"
             filterable
             :filter-method="filterCollection"
             :loading="collectionLoading"
             clearable=""
             :disabled="isEdit"
             :multiple="isMultiple ? true : false">
    <el-option v-for="item in filterOptions"
               :label="item.name"
               :value="item.id"
               :key="item.id">
      <span style="float: left">{{ item.name }}<el-badge :value="item.stock"></el-badge></span>
      <span style="float: right; color: #8492a6; font-size: 13px">
        #{{ item.id }}
        <el-tag>{{ collectionTypeMap[item.type] }}</el-tag>
      </span>
    </el-option>
  </el-select>
</template>
<script>
import { collectionTypeMap } from '@/utils/constant.js';

export default {
    props: ['projectId', 'value', 'isMultiple', 'isEdit'],
    created() {
        if (this.value != null) {
            this.collectionId = this.value;
        }
        this.reloadData();
    },
    data() {
        return {
            options: [],
            filterOptions: [],
            collectionId: null,
            selected: null,
            collectionLoading: false,
            collectionTypeMap
        };
    },
    methods: {
        filterCollection(val) {
            this.filterOptions = this.options.filter(i => i.name.includes(val) || i.id == val);
        },
        reloadData() {
            this.collectionLoading = true;
            this.$http
                .post(
                    '/collection/all',
                    {
                        size: 10000,
                        sort: 'sort,desc;createdAt,desc',
                        query: { del: false, source: 'OFFICIAL', type: 'all', projectId: this.projectId }
                    },
                    { body: 'json' }
                )
                .then(res => {
                    this.filterOptions = this.options = res.content;
                })
                .finally(() => {
                    this.collectionLoading = false;
                });
        }
    },
    watch: {
        collectionId(val) {
            this.$emit('input', val);
            if (val) {
                this.selected = this.options.find(i => i.id === val);
                this.$emit('select', this.selected);
            }
            setTimeout(() => {
                this.filterOptions = [...this.options];
            }, 200);
        },
        value(val) {
            this.collectionId = val;
        },
        projectId() {
            //重新加载数据
            this.reloadData();
        }
    }
};
</script>
