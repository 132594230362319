<template>
    <tinymce-editor :init="init" :value="value" @input="onInput"> </tinymce-editor>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
import tinymce from 'tinymce/tinymce';
import './zh_CN';
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/help';
import 'tinymce/plugins/imagetools';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/icons/default/index';

export default {
    props: ['value', 'disabled'],
    created() {},
    data() {
        return {
            init: {
                language: 'zh_CN',
                theme: 'silver',
                skin: 'oxide',
                menubar: false,
                branding: false,
                statusbar: false,
                height: 400,
                toolbar:
                    'undo redo | styleselect bold italic strikethrough forecolor backcolor  | image media link blockquote visualblocks insert | formatselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | preview fullscreen code help',
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor textcolor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table contextmenu paste code help imagetools'
                ],
                images_upload_url: this.$baseUrl + '/nft/upload/file',
                images_upload_handler: (blobInfo, success, failure) => {
                    let formData = new FormData();
                    formData.append('file', blobInfo.blob(), blobInfo.filename());
                    this.$axios
                        .post('/upload/file', formData)
                        .then(res => {
                            success(res.data);
                        })
                        .catch(e => {
                            failure(e);
                        });
                },
                init_instance_callback: editor => {
                    this.editor = editor;
                },
                file_picker_types: 'media',
                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,
                file_picker_callback: (cb, value, meta) => {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'video/*');
                    input.onchange = e => {
                        var file = input.files[0];

                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            let formData = new FormData();
                            formData.append('file', file);
                            this.$axios
                                .post('/upload/file', formData)
                                .then(res => {
                                    cb(res.data);
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        };
                    };

                    input.click();
                }
            },
            editor: null,
            content: ''
        };
    },
    methods: {
        onInput(val) {
            this.$emit('input', val);
        }
    },
    watch: {},
    components: {
        'tinymce-editor': Editor // <- Important part
    }
};
</script>
<style lang="less" scoped>
.preview {
    overflow: auto;
    border: 1px solid #ebebeb;
    background-color: #fbfdff;
    border-radius: 4px;
}
</style>
