<template>
    <div v-if="value && value.src" class="video-wrapper">
        <video :src="value.src" :poster="value.poster" controls></video>
        <div class="icon-close" @click="remove">
            <i class="el-icon-close"></i>
        </div>
    </div>
    <el-upload
        v-else
        class="video-upload"
        :action="videoUploadUrl"
        :before-upload="beforeUpload"
        :on-progress="onProgress"
        :on-success="onSuccess"
        :on-error="onError"
        :headers="headers"
        :show-file-list="false"
        accept="video/*"
        :disabled="uploading"
    >
        <div class="progress-wrapper" v-if="uploading">
            <i class="el-icon-loading"></i>
            <el-progress :stroke-width="4" :percentage="progress"></el-progress>
        </div>
        <el-button v-else type="primary" size="mini">上传 </el-button>
    </el-upload>
</template>
<script>
import resolveUrl from 'resolve-url';
export default {
    props: ['value'],
    data() {
        return {
            videoUploadUrl: '',
            uploading: false,
            progress: 0
        };
    },
    created() {
        this.videoUploadUrl = resolveUrl(this.$baseUrl, 'upload/video');
    },
    computed: {
        headers() {
            return {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            };
        }
    },
    methods: {
        beforeUpload(file) {
            console.log(file);
            const isVideo = /video\/.*/.test(file.type);
            const isLt100M = file.size / 1024 / 1024 < 100;

            if (!isVideo) {
                this.$message.error('请上传视频文件!');
            }
            if (!isLt100M) {
                this.$message.error('上传视频大小不能超过 100MB!');
            }
            const allow = isVideo && isLt100M;
            if (allow) {
                this.progress = 0;
                this.uploading = true;
            }
            return allow;
        },
        onSuccess(res, file, fileList) {
            console.log(res, file, fileList);
            this.$emit('input', res);
            this.uploading = false;
        },
        onError(err) {
            console.log(err);
            this.uploading = false;
        },
        onProgress(e) {
            console.log(e);
        },
        remove() {
            this.$emit('input', null);
        }
    }
};
</script>
<style lang="less" scoped>
.video-wrapper {
    width: 220px;
    height: 140px;
    position: relative;
    .icon-close {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        color: white;
        background: #f56c6c;
        position: absolute;
        right: -7px;
        top: -7px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
    }
    video {
        width: 100%;
        height: 100%;
        outline: none;
        border-radius: 6px;
        object-fit: cover;
        overflow: hidden;
        background: black;
    }
}
.video-upload {
    .progress-wrapper {
        display: flex;
        height: 28px;
        align-items: center;
        .el-progress {
            width: 350px;
        }
    }
}
</style>
