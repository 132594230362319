<template>
    <el-select v-model="projectId"  :loading="projectLoading" >
        <el-option v-for="item in filterOptions" :label="item.chName" :value="item.id" :key="item.id" :disabled="disabled">
            <span style="float: left">{{ item.chName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">
                #{{ item.id }} 
            </span>
        </el-option>
    </el-select>
</template>
<script>

var cacheProjectList = [];
export default {
    props: ['value','disabled'],
    created() {
        console.log("init projectId:" + this.value);
        if(this.value){
            this.projectId = this.value + "";
        }
        
        if(cacheProjectList && cacheProjectList.length > 0){
            this.filterOptions = this.options = cacheProjectList || []; 
            if((this.projectId === undefined || this.projectId === null) && this.filterOptions.length > 0){
                this.projectId = this.filterOptions[0].id;
                this.selected = this.options.find(i => i.id === this.projectId);
                this.$emit('input', this.projectId); 
                this.$emit('select', this.selected);   
            }
                    
        }else{
            this.reloadData();
        }
        
    },
    data() {
        return {
            options: [],
            filterOptions: [],
            projectId: null,
            selected: null,
            projectLoading:false
        };
    },
    methods: {
        reloadData(){
            this.projectLoading = true;
            this.$http
            .post(
                '/project/all',
                {
                    size: 10000,
                    sort: 'id,asc',
                    query: { del: false}
                },
                { body: 'json' }
            )
            .then(res => {
                this.filterOptions = this.options = res.content || [];                
                if((this.projectId === undefined || this.projectId === null) && this.filterOptions.length > 0){
                    this.projectId = this.filterOptions[0].id;
                }
                this.selected = this.options.find(i => i.id === this.projectId);
                this.$emit('select', this.selected);
                //缓存数据
                cacheProjectList = res.content;
            }).finally(()=>{
                this.projectLoading = false;
            });
        }
    },
    watch: {
        projectId(val) {
            this.$emit('input', val);
            if (val) {
                this.selected = this.options.find(i => i.id === val);
                this.$emit('select', this.selected);
            }

        },
        value(val) {
            this.projectId = val + "";
        },

    }
};
</script>