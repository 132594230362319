import { format } from 'date-fns';
import { zhCN } from 'date-fns/locale';
export default {
    methods: {
        timeFormatter(row, column, cellValue, index) {
            return format(new Date(cellValue), 'HH:mm', { locale: zhCN });
        },
        datetimeFormatter(row, column, cellValue, index) {
            if (!cellValue) return '';
            return format(new Date(cellValue), 'yyyy/MM/dd HH:mm', { locale: zhCN });
        },
        dateFormatter(row, column, cellValue, index) {
            if (cellValue) {
                return format(new Date(cellValue), 'yyyy/MM/dd', { locale: zhCN });
            }
        }
    }
};
