import XLSX from 'xlsx';
function getPropByPath(obj, path, strict) {
    let tempObj = obj;
    path = path.replace(/\[(\w+)\]/g, '.$1');
    path = path.replace(/^\./, '');

    let keyArr = path.split('.');
    let i = 0;
    for (let len = keyArr.length; i < len - 1; ++i) {
        if (!tempObj && !strict) break;
        let key = keyArr[i];
        if (key in tempObj) {
            tempObj = tempObj[key];
        } else {
            if (strict) {
                throw new Error('please transfer a valid prop path to form item!');
            }
            break;
        }
    }
    return {
        o: tempObj,
        k: keyArr[i],
        v: tempObj ? tempObj[keyArr[i]] : null
    };
}
export default {
    install(Vue, option) {
        Vue.prototype.$exportTable = (ref, filename) => {
            if (!ref) {
                throw new Error('ref cannot be emtpy');
            }
            if (!filename) {
                throw new Error('filename cannot be empty');
            }
            filename = filename.replace(/.xlsx$/i, '');
            const columns = ref.columns.filter(column => !!column.property);
            let workbook = XLSX.utils.book_new();
            let sheet = XLSX.utils.aoa_to_sheet([
                columns.map(column => column.label),
                ...ref.tableData.map(row => {
                    return columns.map((column, index) => {
                        const value = getPropByPath(row, column.property).v;
                        if (column.formatter) {
                            return column.formatter(row, column, value, index);
                        }
                        return value;
                    });
                })
            ]);
            XLSX.utils.book_append_sheet(workbook, sheet, filename);
            XLSX.writeFile(workbook, filename + '.xlsx');
        };
    }
};
