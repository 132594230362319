<template>
    <div>
        <div class="upload-wrapper" v-show="disabled" key="2">
            <img v-if="src" :src="src" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div v-if="loading" class="loading"><i class="el-icon-loading"></i></div>
        </div>
        <div id="upload-wrapper" class="upload-wrapper" v-show="!disabled" key="1">
            <img v-if="src" :src="src" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div v-if="loading" class="loading"><i class="el-icon-loading"></i></div>
        </div>
        <avatar-cropper
            ref="cropper"
            @submit="loading = true"
            @uploaded="handleUploaded"
            trigger="#upload-wrapper"
            :cropper-options="cropperOptions"
            :output-options="outputOptions"
            :upload-url="uploadUrl"
            :labels="{ submit: '确定', cancel: '取消' }"
            :upload-headers="headers"
            disabled
        />
    </div>
</template>
<script>
import resolveUrl from 'resolve-url';
import AvatarCropper from 'vue-avatar-cropper';

export default {
    props: {
        value: {},
        width: {
            type: Number,
            default: 350
        },
        height: {
            type: Number,
            default: 350
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'nft/upload/file');
        if (this.value) {
            this.src = this.value;
        }
    },
    mounted() {
        document.body.appendChild(this.$refs.cropper.$el);
    },
    beforeDestroy() {
        document.body.removeChild(this.$refs.cropper.$el);
    },
    data() {
        return {
            uploadUrl: '',
            src: '',
            cropperOptions: {
                aspectRatio: 1
            },
            loading: false,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        };
    },
    computed: {
        outputOptions() {
            return { width: this.width, height: this.height };
        }
    },
    watch: {
        value() {
            if (this.value) {
                this.src = this.value;
            }
        }
    },
    methods: {
        handleUploaded(res) {
            this.loading = false;
            this.src = res;
            this.$emit('input', res);
        }
    },
    components: {
        AvatarCropper
    }
};
</script>
<style lang="less" scoped>
.upload-wrapper {
    width: 178px;
    height: 178px;
    display: block;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }

    &:hover {
        border-color: @prim;
    }
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fbfdff;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    color: #333;
    font-size: 24px;
}
</style>
