import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import http from './plugins/http';
import dataExport from '@/plugins/dataExport';
import SortableHeader from '@/components/SortableHeader';
import MultiUpload from '@/components/MultiUpload';
import SingleUpload from '@/components/SingleUpload';
import FileUpload from '@/components/FileUpload';
import VideoUpload from '@/components/VideoUpload';
import RichText from '@/components/RichText';
import CropUpload from '@/components/CropUpload';
import DistrictChoose from '@/components/DistrictChoose';
import Formatters from '@/mixins/formatters';
import PageTitle from '@/components/PageTitle';
import MinterSelect from '@/components/MinterSelect';
import MinterFilter from '@/components/MinterFilter';
import ObjectUpload from '@/components/ObjectUpload';
import CollectionSearch from '@/components/CollectionSearch';
import CreatedAtPicker from '@/components/CreatedAtPicker';
import ProjectSelect from '@/components/ProjectSelect';


import 'normalize.css/normalize.css';
import './styles/element_theme/index.css';
import theme from '!less-vars-loader!./styles/common/theme.less';

// import VueAMap from "vue-amap";
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//     key: 'bf91055058a47a7dc387e40ab6256a5f',
//     plugin: [
//         'Autocomplete',
//         'PlaceSearch',
//         'Scale',
//         'OverView',
//         'ToolBar',
//         'MapType',
//         'PolyEditor',
//         'AMap.CircleEditor',
//         'AMap.Geolocation',
//         'AMap.DistrictSearch',
//         'AMap.Geocoder'
//     ],
//     uiVersion: '1.1',
//     v: '1.4.15'
// });

Vue.config.productionTip = false;
Vue.use(ElementUI, { size: 'small' });
Vue.use(http);
Vue.use(dataExport);
Vue.component('sortable-header', SortableHeader);
Vue.component('multi-upload', MultiUpload);
Vue.component('single-upload', SingleUpload);
Vue.component('file-upload', FileUpload);
Vue.component('video-upload', VideoUpload);
Vue.component('rich-text', RichText);
Vue.component('crop-upload', CropUpload);
Vue.component('district-choose', DistrictChoose);
Vue.component('page-title', PageTitle);
Vue.component('minter-select', MinterSelect);
Vue.component('minter-filter', MinterFilter);
Vue.component('object-upload', ObjectUpload);
Vue.component('collection-search', CollectionSearch);
Vue.component('created-at-picker', CreatedAtPicker);
Vue.component('project-select', ProjectSelect);
Vue.mixin(Formatters);
Vue.prototype.$theme = theme;
console.log(theme);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
