<template>
    <span>
        <span class="sortable-header" @click="changeSort('')">{{ column.label }}</span>
        <span class="sort-caret-wrapper">
            <i
                class="sort-caret asc"
                @click="changeSort('asc')"
                :class="{ active: currentSort[column.property] === 'asc' }"
            ></i>
            <i
                class="sort-caret desc"
                @click="changeSort('desc')"
                :class="{ active: currentSort[column.property] === 'desc' }"
            ></i>
        </span>
    </span>
</template>
<script>
export default {
    name: 'sortbaleHeader',
    props: {
        column: {
            type: Object,
            required: true
        },
        currentSort: {
            type: Object,
            required: true
        }
    },
    methods: {
        changeSort(order) {
            this.$emit('changeSort', this.column.property, order);
        }
    }
};
</script>
