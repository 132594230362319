<template>
    <div class="preview" v-if="fileObject.url">
        <el-image
            style="width: 100%; height: 100%"
            :src="fileObject.thumb || fileObject.url"
            :preview-src-list="[fileObject.thumb || fileObject.url]"
            fit="contain"
            ref="img"
        ></el-image>
        <div class="preview-btns">
            <i class="el-icon-view" @click="preview"></i>
            <i class="el-icon-delete" @click="del" v-if="!disabled"></i>
        </div>
        <el-dialog :visible.sync="showVideoDialog" width="800px" :before-close="beforeCloseVideo">
            <video
                ref="video"
                :src="fileObject.url"
                :poster="fileObject.thumb"
                controls
                style="width: 100%; height: 60vh; object-fit: contain"
            ></video>
        </el-dialog>
    </div>
    <el-upload
        v-else
        class="avatar-uploader"
        :action="finalUploadUrl"
        :show-file-list="false"
        :on-success="onSuccess"
        :before-upload="beforeUpload"
        :on-error="onError"
        :disabled="disabled"
    >
        <div class="upload" v-loading="loading">
            <i class="el-icon-plus"></i>
        </div>
    </el-upload>
</template>
<script>
import resolveUrl from 'resolve-url';
import qs from 'qs';
export default {
    props: {
        value: {},
        disabled: {
            default: false,
            type: Boolean
        },
        compress: {
            default: false,
            type: Boolean
        },
        width: {},
        height: {}
    },
    data() {
        return {
            fileObject: {},
            loading: false,
            showVideoDialog: false
        };
    },
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'nft/upload/fileObject');
        this.fileObject = this.value || {};
    },
    computed: {
        finalUploadUrl() {
            let data = { compress: this.compress };
            if (this.width) {
                data.width = this.width;
            }
            if (this.height) {
                data.height = this.height;
            }
            return this.uploadUrl + '?' + qs.stringify(data);
        }
    },
    methods: {
        onSuccess(e) {
            console.log(e);
            this.loading = false;
            this.fileObject = e;
        },
        beforeUpload(file) {
            if (!/\.(jpg|jpeg|png|gif|mp4)$/i.test(file.name)) {
                this.$message.error('仅支持jpg、png、gif、mp4');
                return false;
            }
            this.loading = true;
            return true;
        },
        onError(e) {
            this.$message.error('上传失败');
            this.loading = false;
        },
        del() {
            this.fileObject = {};
        },
        preview() {
            if (/\.mp4$/i.test(this.fileObject.url)) {
                this.showVideoDialog = true;
                this.$nextTick(() => {
                    this.$refs.video.play();
                });
            } else {
                this.$refs.img.showViewer = true;
            }
        },
        beforeCloseVideo(done) {
            this.$refs.video.pause();
            done();
        }
    },
    watch: {
        value(val) {
            this.fileObject = val || {};
        },
        fileObject(val) {
            this.$emit('input', val);
        }
    }
};
</script>
<style lang="less" scoped>
.upload {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #409eff;
    border-radius: 8px;
    overflow: hidden;
    i {
        font-size: 24px;
        color: #409eff;
    }
}
.preview {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #409eff;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .preview-btns {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 24px;
        i {
            margin: 0 10px;
            cursor: pointer;
            text-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
        }
    }
    &:hover {
        .preview-btns {
            display: flex;
        }
    }
}
</style>
