<template>
    <section>
        <el-upload
            list-type="picture-card"
            :action="uploadUrl"
            :headers="headers"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :file-list="fileList"
            :accept="accept"
            multiple
        >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
                <slot></slot>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <el-image
            style="width: 0; height: 0; position: absolute"
            ref="preview"
            :src="previewUrl"
            :preview-src-list="previewList"
        >
        </el-image>
    </section>
</template>
<script>
import resolveUrl from 'resolve-url';
export default {
    created() {
        this.uploadUrl = resolveUrl(this.$baseUrl, 'nft/upload/file?compress=' + this.compress);
        this.updateFileList(this.value);
    },
    props: {
        value: Array,
        usePrefix: {
            type: Boolean,
            default: true
        },
        url: {
            type: String
        },
        accept: {
            default: '*/*'
        },
        compress: { type: Boolean, default: false }
    },
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            fileList: [],
            uploadUrl: '',
            realFileList: [],
            previewUrl: '',
            previewList: []
        };
    },
    computed: {
        headers() {
            return {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            };
        }
    },
    methods: {
        handleRemove(file, fileList) {
            this.realFileList = fileList;
            this.$emit(
                'input',
                fileList.map(i => i.value)
            );
        },
        handlePictureCardPreview(file) {
            this.previewUrl = file.value;
            this.previewList = this.fileList.map(i => i.value);
            console.log(this.$refs.preview.$el);
            this.$refs.preview.showViewer = true;
        },
        handleSuccess(res, file, fileList) {
            if (res instanceof Array) {
                file.value = res[0];
            } else {
                file.value = res;
            }
            this.realFileList = fileList;
            this.$emit(
                'input',
                fileList.map(i => i.value)
            );
        },
        updateFileList(list) {
            if (!list) {
                list = [];
            } else if (typeof list == 'string') {
                list = list.split(',');
            }
            for (let i = 0; i < list.length; i++) {
                if (!this.fileList[i]) {
                    this.fileList[i] = {
                        value: list[i],
                        url: list[i]
                    };
                } else if (this.fileList[i].value !== list[i]) {
                    this.fileList[i] = {
                        value: list[i],
                        url: list[i]
                    };
                }
            }
            this.fileList.splice(list.length);
            // this.fileList = list.map(i => {
            //     return {
            //         value: i,
            //         url: i,
            //     };
            // });
        }
    },
    watch: {
        value(val, oldVal) {
            if (JSON.stringify(val) == JSON.stringify(this.realFileList.map(i => i.value))) {
                return;
            }
            this.updateFileList(val);
        }
    }
};
</script>
<style lang="less" scoped></style>
