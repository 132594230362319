<template>
    <el-select
        v-model="minterId"
        filterable
        remote
        reserve-keyword
        placeholder="请输入铸造者"
        :remote-method="searchMinter"
        :loading="searchingMinter"
        @change="changeSelect"
        :disabled="disabled"
        class="filter-item"
        clearable
    >
        <el-option v-for="item in minters" :key="item.id" :label="item.nickname" :value="item.id" class="minter-item">
            <el-image :src="item.avatar" fit="cover" class="avatar"></el-image>
            <div class="content">
                <div class="name">{{ item.nickname }}</div>
                <div class="id">#{{ item.id }}</div>
            </div>
        </el-option>
    </el-select>
</template>
<script>
export default {
    props: {
        value: {},
        disabled: {
            default() {
                return false;
            }
        },
        projectId: {}
    },
    data() {
        return {
            minters: [],
            minterId: null,
            searchingMinter: false,
            selected: null,
            id: 0
        };
    },
    created() {
        this.id = this.projectId;
        this.getData();
    },
    methods: {
        searchMinter(query) {
            this.searchingMinter = true;
            this.$http
                .post(
                    '/user/all',
                    {
                        search: query,
                        size: 30,
                        query: { hasRole: 'ROLE_MINTER', minterProjectId: this.id }
                    },
                    { body: 'json' }
                )
                .then(res => {
                    this.minters = res.content;
                    this.searchingMinter = false;
                });
        },
        changeSelect(val) {
            this.$emit('input', val);
        },
        getData() {
            this.$http
                .post(
                    '/user/all',
                    { size: 30, query: { hasRole: 'ROLE_MINTER', minterProjectId: this.id } },
                    { body: 'json' }
                )
                .then(res => {
                    this.minters = res.content;
                });
        }
    },
    watch: {
        value(val) {
            if (this.minterId !== val) {
                this.minterId = val;
            }
        },
        projectId(val) {
            if (this.id !== val) {
                this.id = val;
                this.getData();
            }
        }
    }
};
</script>
<style lang="less" scoped>
.minter-item {
    .flex();
    height: 44px;
    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    .content {
        margin-left: 10px;
        line-height: 1;
        .flex-col();
        justify-content: center;
        .name {
            font-size: 14px;
            color: @text2;
        }
        .id {
            font-size: 12px;
            color: @text3;
            margin-top: 5px;
        }
    }
}
</style>